import { useMemo } from "react"
import * as PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "@/styled/GlobalStyles"
import initializers from "Initializers"
import { SpeedInsights } from "@vercel/speed-insights/next"
import { Analytics } from "@vercel/analytics/next"
import "index.css"

const theme = {
  colors: {
    // primary palette
    darkBlue: "#021859",
    skyBlue: "#1a73e8", // "#0B9ED9",
    deepPink: "#021859", // "#FF4597",
    // secondary palette
    mediumBlue: "#0433BF",
    purple: "#B850FF",
    mediumVioletRed: "#BB3F75",
    yellow: "#FFC700",
    lightGreen: "#54D6A6",
    crimson: "#E81E61",
    // gray palette
    dimGray: "#616670",
    gray: "#ACB5C6",
    silver: "#C3CDE0",
    lightGray: "#E6E8F0",
    backgroundGray: "#F7F7FA",
    white: "#FFFFFF"
  },
  breakpoints: {
    sm: "(min-width: 576px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 992px)",
    xl: "(min-width: 1200px)"
  },
  weights: {
    light: 300,
    regular: 400,
    medium: 600,
    bold: 600
  }
}

export default function MyApp(props) {
  const { Component, pageProps, err = null } = props

  useMemo(initializers, [])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Component {...pageProps} err={err} />
      <SpeedInsights />
      <Analytics />
    </ThemeProvider>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
  err: PropTypes.object
}
